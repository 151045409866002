import { createSlice } from '@reduxjs/toolkit';

import { axiosWallet } from '../../utils/axios'

import { dispatch } from '../store';
import { TokenList, TokenState, TokenWallet } from "../../@types/token";

const initialState: TokenState = {
    isLoading: false,
    error: null,
    token: null,
    tokens: [],
    tokensAll: [],
    sortBy: null,
    infoTable: null,
}

const slice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET TOKENS
        getTokenSuccess(state, action) {
            state.isLoading = false;
            state.token = action.payload;
            // state.infoTable = {
            //     total_pages: action.payload.total_pages,
            //     total_elements: action.payload.total_elements,
            //     number: action.payload.number,
            //     size: action.payload.size,
            //     first: action.payload.first,
            //     last: action.payload.last,
            //     has_next: action.payload.has_next,
            //     has_previous: action.payload.has_previous,
            // }
        },

        getTokensSuccess(state, action) {
            state.isLoading = false;
            state.tokens = action.payload;
            // state.infoTable = {
            //     total_pages: action.payload.total_pages,
            //     total_elements: action.payload.total_elements,
            //     number: action.payload.number,
            //     size: action.payload.size,
            //     first: action.payload.first,
            //     last: action.payload.last,
            //     has_next: action.payload.has_next,
            //     has_previous: action.payload.has_previous,
            // }
        },

        getTokensAllSuccess(state, action) {
            state.isLoading = false;
            state.tokensAll = action.payload.content;
        },

        // SORT TOKENS
        sortByToken(state, action) {
            state.sortBy = action.payload;
        },
    }
});

export default slice.reducer;

export const {
    sortByToken
} = slice.actions;

export const getToken = (platform: string, contract: string ) => async () => {
    dispatch(slice.actions.startLoading());
    try {
        const response: { data: TokenWallet } = await axiosWallet.get(`/token/bound/${platform}/${contract}`, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                'Client-Space-Id': `${localStorage.getItem('spaceHash')}`
            },
        });

        dispatch(slice.actions.getTokenSuccess(response.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const getTokens = (query?: any) => async () => {
    dispatch(slice.actions.startLoading());
    try {
        const response: { data: TokenWallet } = await axiosWallet.get('/token/bound', {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                'Client-Space-Id': `${localStorage.getItem('spaceHash')}`
            },
            params: {
                page: 0,
                size: 20,
                platform: "ERC20, BEP20, ERC20_MATIC, TRC20, SPL",
                ...query
            }
        });

        dispatch(slice.actions.getTokensSuccess(response.data));
    } catch (error) {
        dispatch(slice.actions.hasError(error));
    }
}

export const getTokensAll = (query: object) => async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: { data: TokenList } = await axiosWallet.get('/token/all', {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                    'Client-Space-Id': `${localStorage.getItem('spaceHash')}`
                }
            });

            dispatch(slice.actions.getTokensSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }