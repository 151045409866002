import { axiosInvocer } from './axios';
import { getInvoicesID } from './getInvoicesID';

export const checkInvoicesIDinDatabase = (data: string[]) =>
   axiosInvocer.post('/invoices/check-numbers', data, {
    headers: {
      "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
    }
});


export const checkAndSetExistingIds = async (data: any[], setWrongID: (ids: string[]) => void) => {
  const idsFromCSV = getInvoicesID(data)?.filter((id) => id !== "");

  if (idsFromCSV?.length) {
    try {
      const { data: responseData } = await checkInvoicesIDinDatabase(idsFromCSV);
      setWrongID(responseData?.existedInvoiceNumbers || []);
    } catch (error) {
      console.error('Error checking existing IDs:', error);
      setWrongID([]);
    }
  } else {
    setWrongID([]);
  }
};