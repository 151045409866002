import { createSlice } from '@reduxjs/toolkit';

import { axiosWallet } from '../../utils/axios'

import { dispatch } from '../store';
import { TransactionState, TransactionData } from "../../@types/transaction";

const initialState: TransactionState = {
    isLoading: false,
    error: null,
    transactions: [],
    tokens: [],
    transaction: null,
    infoTable: null
}

const slice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET Transactions
        getTransactionsSuccess(state, action) {
            state.isLoading = false;
            state.transactions = action.payload.content;
            state.infoTable = {
                total_pages: action.payload.total_pages,
                total_elements: action.payload.total_elements,
                number: action.payload.number,
                size: action.payload.size,
                first: action.payload.first,
                last: action.payload.last,
                has_next: action.payload.has_next,
                has_previous: action.payload.has_previous,
            }
        },

        getTokensSuccess(state, action) {
            state.tokens = [
                ...state.tokens,
                ...action.payload.content
            ];
        }

    }
});

export default slice.reducer;

export const getTransactions = (query: object) => async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: { data: TransactionData } = await axiosWallet.get('/transaction', {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                    'Client-Space-Id': `${localStorage.getItem('spaceHash')}`,
                    'X-Client-Version' : '2.0.0'
                },
                params: {
                    ...query
                }
            });

            dispatch(slice.actions.getTransactionsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }

export const getTokens = (query: object) => async () => {
        try {
            const response: { data: TransactionData } = await axiosWallet.get('/token/all', {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
                    'Client-Space-Id': `${localStorage.getItem('spaceHash')}`,
                    'X-Client-Version' : '2.0.0'
                },
                params: {
                    ...query
                }
            });

            dispatch(slice.actions.getTokensSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }