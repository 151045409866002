import axios from 'axios';
// config
import { USER_API } from '../../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: USER_API,
});

// if (localStorage.getItem('access_token'))
//   axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access_token')}`;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
