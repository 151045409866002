import { createSlice } from '@reduxjs/toolkit';

import { axiosInvocer } from '../../utils/axios'

import { dispatch } from '../store';
import { IRecipientState } from "../../@types/recipient";
import { IInvoice, InvoiceState, IQueryInvoice, IQueryInvoiceUser, ISummary } from '../../@types/invoice';
import moment from 'moment';

const initialState: InvoiceState = {
  isLoading: false,
  error: null,
  invoices: null,
  invoice: null,
  inbox: null,
  outbox: null,
  step: 0,
  selected: [],
  summary: null,
  isEdit: true,
  currencies: null,
}

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Bill
    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },

    // GET LIST Invoices
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET LIST INVOICES INBOX
    getInboxInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.inbox = action.payload;
    },
    // GET LIST INVOICES INBOX
    getOutboxInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.outbox = action.payload;
    },
    // GET LIST INVOICES INBOX
    updateSuccess(state) {
      state.isLoading = false;
    },

    selectTransfers(state, action) {
      state.isLoading = false;
      state.selected = action.payload
      state.summary = null;
      state.step = 0;
      state.isEdit = false;
    },

    selectApprovalTransfers(state, action) {
      state.isLoading = false;
      state.selected = action.payload
      state.summary = null;
      state.step = 2;
    },

    createEditInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.selected = action.payload;
      state.step = 0;
      state.isEdit = false;
    },

    getSummarySuccess(state, action) {
      state.isLoading = false;
      state.summary = action.payload;
    },

    getCurrenciesRatesSuccess(state, action) {
      state.isLoading = false;
      state.currencies = action.payload;
    },

    nextStep(state) {
      state.isLoading = false;
      state.step += 1;
    },

    prevStep(state) {
      state.isLoading = false;
      state.error = null;
      state.step -= 1;
    },

    getPaySuccess(state) {
      state.isLoading = false;
      state.step = 3;
    },

    deletedSuccess(state, action) {
      state.isLoading = false;
      state.step = 3;
    },

    clearTransfers(state) {
      state.selected = [];
      state.step = 0;
      state.isLoading = false;
      state.error = null;
      state.isEdit = true;
    },

    updateInvoices(state, action) {
      const ids = action.payload?.map((item: any) => item.id);
      state.selected = state?.selected ? state?.selected?.map((item: IInvoice) => ids?.includes(item.id) ? {
        ...item,
        status: action.payload.find((inv: IInvoice) => inv.id === item.id)?.status ?? item.status
      } : item) : [];
    },

    removeItem(state, action) {
      state.selected = [
        ...state?.selected?.filter((item: any) => item?.id !== action?.payload) ?? []
      ]
    },

    notificationInvoices(state, action) {
      const ids = action.payload?.map((item: any) => item.id);
      state.invoices = state.invoices ? {
        ...state.invoices,
        content: state.invoices?.content.map((item) => ids?.includes(item.id) ? {
          ...item,
          status: action.payload.find((inv: any) => inv.id === item.id)?.status ?? item.status
        } : item) ?? []
      } : null;
      state.outbox = state.outbox ? {
        ...state.outbox,
        content: state.outbox?.content.map((item) => ids?.includes(item.id) ? {
          ...item,
          status: action.payload.find((inv: any) => inv.id === item.id)?.status ?? item.status
        } : item) ?? []
      } : null;
    },

    clearError(state) {
      state.isLoading = false;
      state.error = null;
    },
  }
});

export default slice.reducer;

export const {
  selectTransfers,
  selectApprovalTransfers,
  prevStep,
  nextStep,
  updateInvoices,
  clearTransfers,
  removeItem,
  notificationInvoices,
  clearError
} = slice.actions;

export const createInvoice = (invoice: any) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: any = await axiosInvocer.post("/invoices",
      invoice, {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        }
      }
    );

    dispatch(slice.actions.createEditInvoicesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getListInvoices = (query?: IQueryInvoice) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IRecipientState } = await axiosInvocer.get('/invoices', {
      params: {
        archived: query?.status === "ARCHIVED",
        size: query?.size ?? undefined,
        page: query?.page ?? undefined,
        sort: `${query?.orderBy},${query?.order}`,
        status: query?.status && !["ALL", "ARCHIVED"]?.includes(query?.status) ? query?.status : undefined,
        generalSearch: query?.generalSearch ? query?.generalSearch : undefined,
        dateFrom: query?.dateFrom ? moment(query?.dateFrom).format('YYYY-MM-DD') : undefined,
        dateTo: query?.dateTo ? moment(query?.dateTo).format('YYYY-MM-DD') : undefined,
      },
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getInvoicesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getListInboxInvoices = (query?: IQueryInvoice) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IRecipientState } = await axiosInvocer.get('/invoices/inbox', {
      params: {
        size: query?.size ?? undefined,
        page: query?.page ?? undefined,
        sort: `${query?.orderBy},${query?.order}`,
        status: query?.status !== "ALL" ? query?.status : undefined
      },
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getInboxInvoicesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getListOutboxInvoices = (query?: IQueryInvoice) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IRecipientState } = await axiosInvocer.get('/invoices/outbox', {
      params: {
        archived: false,
        size: query?.size ?? undefined,
        page: query?.page ?? undefined,
        sort: query?.orderBy ? `${query?.orderBy},${query?.order}` : undefined,
        ids: query?.ids ?? undefined,
        generalSearch: query?.generalSearch ? query?.generalSearch : undefined,
        dateFrom: query?.dateFrom ? moment(query?.dateFrom).format('YYYY-MM-DD') : undefined,
        dateTo: query?.dateTo ? moment(query?.dateTo).format('YYYY-MM-DD') : undefined,
        status: query?.status !== "ALL" ? query?.status : undefined
      },
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getOutboxInvoicesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getListPayersInvoices = (id: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IRecipientState } = await axiosInvocer.get(`/invoices/payer/${id}`, {
      // params: {
      //   size: query?.size ?? undefined,
      //   page: query?.page ?? undefined,
      //   sort: `${query?.orderBy},${query?.order}`
      // },
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getInboxInvoicesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const batchCreateInvoices = (invoices: any[]) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const listNewInvoice = invoices?.filter((item) => !item?.id);
    const listOldInvoice = invoices?.filter((item) => item?.id);

    const response = listNewInvoice?.length > 0 ? await axiosInvocer.post("/invoices/batch", listNewInvoice, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }) : null;

    const listUpdated = await Promise.all(listOldInvoice?.map((item) => axiosInvocer.put(`/invoices/${item?.id}`, {
      ...item,
      externalRecipientId: item.externalRecipient.id ?? item.externalRecipientId,
      cryptoCurrencyAddressId: item.cryptoCurrencyAddress.id ?? item.cryptoCurrencyAddressId
    }, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
      .then((res) => res.data)));

    dispatch(slice.actions.createEditInvoicesSuccess([
      ...(response?.data ?? []),
      ...listUpdated
    ]));

    return ([
      ...(response?.data ?? []),
      ...listUpdated
    ])
  } catch (error) {
    console.error('error', error);
    dispatch(slice.actions.hasError(error));
    return error
  }
}

export const duplicateInvoices = (invoices: any[]) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axiosInvocer.post("/invoices/batch", invoices, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    dispatch(slice.actions.createEditInvoicesSuccess(response.data));

    return response.data;
  } catch (error) {
    console.error('error', error);
    dispatch(slice.actions.hasError(error));
    return error
  }
}

export const getListRecipientsInvoices = (query?: IQueryInvoiceUser) => async () => {
  dispatch(slice.actions.startLoading());
  try {

    const response: { data: IRecipientState } = await axiosInvocer.get(`/invoices/recipients/${query?.id}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getInboxInvoicesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getInvoice = (id: string | undefined) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IInvoice } = await axiosInvocer.get(`/invoices/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    dispatch(slice.actions.getInvoiceSuccess(response.data));
    return response.data;
  } catch (error) {
    console.log('error', error);
    dispatch(slice.actions.hasError(error));
  }
}

export const deleteInvoice = (invoiceID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    await axiosInvocer.delete(`/invoices/${invoiceID}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    })
    dispatch(slice.actions.updateSuccess());
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
}

export const getSummary = (ids: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: ISummary[] } = await axiosInvocer.post('/invoices/summary', {}, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
      params: {
        ids
      },
    });

    dispatch(slice.actions.getSummarySuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const confirmInvoices = (ids: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    await axiosInvocer.post('/invoices/process', {},{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
      params: {
        ids
      },
    });
    dispatch(slice.actions.nextStep());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const payInvoices = (summary: ISummary[]) => async () => {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.nextStep());
  try {
    await axiosInvocer.post('/invoices/pay', summary,{
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getPaySuccess());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const payInvoicesWithoutSummary = (ids: string) => async () => {
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.nextStep());
  try {
    await axiosInvocer.post('/invoices/pay', {}, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
      params: {
        ids
      },
    });

    dispatch(slice.actions.getPaySuccess());
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getCurrenciesRates = (walletID: string) =>async () => {
  dispatch(slice.actions.startLoading());

  try {
    const response: { data: any } = await axiosInvocer.get(`/currencies/${walletID}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getCurrenciesRatesSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};