import { createSlice } from '@reduxjs/toolkit';

import { axiosWallet } from '../../utils/axios'

import { dispatch } from '../store';
import { Wallet, WalletState, WalletTABS } from "../../@types/wallet";
import { currency } from "../../_mock/map/currency";

const initialState: WalletState = {
  isLoading: false,
  error: null,
  all: [],
  walletTabs: [],
  wallets: [],
  wallet: null,
  sortBy: null,
  infoTable: null,
}

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WALLETS
    getWalletsSuccess(state, action) {
      state.isLoading = false;
      state.wallets = action.payload.content;
      state.infoTable = {
        total_pages: action.payload.total_pages,
        total_elements: action.payload.total_elements,
        number: action.payload.number,
        size: action.payload.size,
        first: action.payload.first,
        last: action.payload.last,
        has_next: action.payload.has_next,
        has_previous: action.payload.has_previous,
      }
    },

    getAllWallets(state, action) {
      state.isLoading = false;
      state.all = action.payload;
    },

    getTabsWallets(state, action) {
      state.isLoading = false;
      state.walletTabs = action.payload;
    },

    // GET WALLET
    getWalletSuccess(state, action) {
      state.isLoading = false;
      state.wallet = action.payload;
    },

    // DELETE WALLET
    deleteWallet(state, action) {
      state.isLoading = false;
    },
  }
});

export default slice.reducer;

// export const {
//     sortByProducts
// } = slice.actions;

export const getAllWallets = () => async () => {
  try {
    const response: { data: Wallet[] } = await axiosWallet.get('/wallet/all', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        'Client-Space-Id': `${localStorage.getItem('spaceHash')}`,
      },
      params: {
        include_gas_tank: true
      }
    });
    let tabs: WalletTABS[] = [];

    currency.forEach((name) => {
      const count = response.data.filter((ele) => ele.currency.code === name).length
      count > 0 && tabs.push({
        value: name,
        label: name,
        color: 'info',
        count: count
      })
    })

    dispatch(slice.actions.getAllWallets(response.data));
    dispatch(slice.actions.getTabsWallets(tabs));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getWallets = (query: object) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axiosWallet.get('/wallet', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        'Client-Space-Id': `${localStorage.getItem('spaceHash')}`,
      },
      params: {
        ...query,
        include_gas_tank: true
      }
    });

    dispatch(slice.actions.getWalletsSuccess(response.data));
  } catch (error) {
    console.log(error);
    dispatch(slice.actions.hasError(error));
  }
}

export const getWallet = (wallet_id: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: Wallet } = await axiosWallet.get(`/wallet/${wallet_id}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        'Client-Space-Id': `${localStorage.getItem('spaceHash')}`,
      },
    });
    dispatch(slice.actions.getWalletSuccess(response.data));
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
}

export const deleteWallet = (walletID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axiosWallet.delete(`/wallet/${walletID}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
        'Client-Space-Id': `${localStorage.getItem('spaceHash')}`,
      },
    })
    dispatch(slice.actions.deleteWallet(response.data));
  } catch (error) {
    console.error(error);
    dispatch(slice.actions.hasError(error));
  }
}