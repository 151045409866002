// noinspection Annotator

import { createSlice } from '@reduxjs/toolkit';

import { axiosInvocer } from '../../utils/axios'

import { dispatch } from '../store';
import { IBalance, IBalanceFB } from '../../@types/wallet';
import { getElements } from '../../utils/ArrayFormater';
import uuidv4 from '../../utils/uuidv4';
import { IAdminState } from '../../@types/admin';
import { IQueryInvoice } from '../../@types/invoice';
import { IRecipientState } from '../../@types/recipient';
import moment from 'moment/moment';

const initialState: IAdminState = {
  isLoading: false,
  error: null,
  balances: [],
  invoices: null,
  invoice: null
}

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Bill
    getBalancesSuccess(state, action) {
      state.isLoading = false;
      state.balances = action.payload;
    },

    getInvoicesListSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    }
  }
});

export default slice.reducer;

// export const {
//     sortByProducts
// } = slice.actions;

export const getListBalancesAdmin = (userID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IBalanceFB[] } = await axiosInvocer.get(`/admin/balances/user/${userID}`, {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    const list = response?.data?.filter((item) => !['TRX', 'ETH']?.includes(item.currency))
      ?.sort((a, b) => a?.available > b?.available ? -1 : 1 )
      ?.map((item) => ({
        ...item,
        id: uuidv4(),
        env: item?.env
          ?.replaceAll('EU', 'OTC')
          ?.replaceAll('MAIN', 'Invoicer'),
        vaultName: item?.vaultName
          ?.split('_')[2] ?? 'Current'
      }));

    const listENV = getElements(list, 'env');
    const listVault = getElements(list, 'vaultName');

    let finishList: IBalance[] = [];

    listENV.forEach(( env => listVault.forEach((vault) => {
      const listVault = list?.filter((balance) => balance?.env === env && balance?.vaultName === vault);
      if (listVault.length > 0) {
        finishList.push({
          id: uuidv4(),
          env,
          vault,
          listVault
        });
      }
    })));


    dispatch(slice.actions.getBalancesSuccess(finishList));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getListInvoicesAdmin = ({ query, userID }: {
  query?: IQueryInvoice,
  userID: string
}) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IRecipientState } = await axiosInvocer.get(`/admin/invoices/user/${userID}`, {
      params: {
        archived: query?.status === "ARCHIVED",
        size: query?.size ?? undefined,
        page: query?.page ?? undefined,
        sort: `${query?.orderBy},${query?.order}`,
        status: query?.status && !["ALL", "ARCHIVED"]?.includes(query?.status) ? query?.status : undefined,
        generalSearch: query?.generalSearch ? query?.generalSearch : undefined,
        dateFrom: query?.dateFrom ? moment(query?.dateFrom).format('YYYY-MM-DD') : undefined,
        dateTo: query?.dateTo ? moment(query?.dateTo).format('YYYY-MM-DD') : undefined,
      },
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getInvoicesListSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}