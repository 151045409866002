import { createSlice } from '@reduxjs/toolkit';

import { axiosInvocer } from '../../utils/axios'

import { dispatch } from '../store';
import {
  PayerState,
  IExternalPayerState,
  IPayerState, IPayer, IExternalPayer,
} from "../../@types/payer";

const initialState: PayerState = {
  isLoading: false,
  error: null,
  externalPayers: null,
  payers: null,
  user: null,
  externalPayer: null,
  invoices: null
}

const slice = createSlice({
  name: 'payers',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MERCHANT
    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // GET LIST EXTERNAL MERCHANTS
    getExternalPayers(state, action) {
      state.isLoading = false;
      state.externalPayers = action.payload;
    },

    // GET LIST MERCHANTS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.payers = action.payload;
    },

    // DELETE EXTERNAL MERCHANT
    deleteExternalPayers(state, action) {
      state.isLoading = false;
      console.log(action.payload);
    },

    // GET EXTERNAL MERCHANT
    getExternalPayer(state, action) {
      state.isLoading = false;
      state.externalPayer = action.payload;
    },

    getExternalPayerInvoices(state, action) {
      state.isLoading = false;
      state.externalPayer = action.payload;
    },
  }
});

export default slice.reducer;

// export const {
//     sortByProducts
// } = slice.actions;

export const getListExternalPayers = () => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IExternalPayerState } = await axiosInvocer.get('/external_payers', {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    dispatch(slice.actions.getExternalPayers(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getListPayers = () => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IPayerState } = await axiosInvocer.get('/users/payers', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    dispatch(slice.actions.getUsersSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const deleteExternalPayer = (id: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axiosInvocer.delete(`/external_payers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    dispatch(slice.actions.deleteExternalPayers(response));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getExternalPayer = (id: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IExternalPayer } = await axiosInvocer.get(`/external_payers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    dispatch(slice.actions.getExternalPayer(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getExternalPayerInvoices = (id: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await axiosInvocer.get(`/invoices/external_payers/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    dispatch(slice.actions.getExternalPayerInvoices(response?.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}

export const getUser = (payerID: string) => async () => {
  dispatch(slice.actions.startLoading());
  try {
    const response: { data: IPayer } = await axiosInvocer.get(`/users/${payerID}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    dispatch(slice.actions.getUserSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
}
